import { format as formatDate } from 'date-fns';

import { UNIT } from './constants';

import { DATE_FORMAT_DEFAULT } from '../config/locale';

import { dateInputValidation as dateInputValidationRegex } from './regexes';

export default function Format(date, format = DATE_FORMAT_DEFAULT, placeholder = '') {
  return date ? formatDate(date, format) : placeholder;
}

export const formatMMtoM = n => (n ? `${n / UNIT}` : undefined);

export const formatMToMM = (m) => Math.round(m * 1000);

export function formatPrice(price) {
  return price.toFixed(2);
}

export function formatPriceWithCurrency(price, currency = 'GBP') {
  const parsedPrice = typeof price === 'string' ? parseFloat(price) : price;
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency }).format(parsedPrice).replace('-', '- ');
}

export function formatTimestamp(timestamp, format = 'YYYY-MM-DD', options = {}) {
  if (!timestamp) return '';
  return formatDate(Number(timestamp), format, options);
}

export function formatToHyphenFormat(date) {
  if (!date) return '';
  if (date.includes('-')) return date;

  const dateArr = date.split('/');
  const newDate = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
  return newDate || '';
}

export function formatDatepickerDate(dateWithSlashes = '') {
  if (dateInputValidationRegex.test(dateWithSlashes)) {
    return formatToHyphenFormat(dateWithSlashes);
  }

  return dateWithSlashes || '';
}

// Formats to ISO format without timezone
export function formatToISOWithoutTimezone(date, time) {
  const utcDate = new Date(date);
  const utcWithoutTimezone = `${formatDate(utcDate, 'YYYY-MM-DD')}T${time.length === 1 ? `0${time}` : time}:00`;

  return utcWithoutTimezone;
}

export function formatCapsToSpaces(str) {
  return str.split(/(?=[A-Z])/).join(' ');
}

export function formatTitleCase(str) {
  if (!str) {
    return '';
  }
  const titleCaseString = str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  return titleCaseString;
}

export function formatInternationalTelephone(data) {
  return `${data.countryPrefix ? `+${data.countryPrefix} ` : ''}${data.number ? data.number : '-'}`;
}

export const round = (number, decimalPlaces = 0) => Math.round(
  number * 10 ** decimalPlaces,
) / 10 ** decimalPlaces;

// Rounds a given number to the nearest roundTo value.
// e.g. roundToNearest(2456, 10) => 2460 & e.g. roundToNearest(2456, 100) => 2500
export const roundToNearest = (number, roundTo) => {
  if (!number || !roundTo) return number;
  return Math.round(number / roundTo) * roundTo;
};
