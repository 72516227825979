import styled, { css } from 'styled-components';

import { disabledCSS, field } from '../Form.style';

export default styled.input`
  -webkit-appearance: none;
  ${field}

  ${({ customStyles }) => customStyles && css`${customStyles}`}

  ${({ disabled }) => disabled && disabledCSS}
`;
