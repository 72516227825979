import styled, { css } from 'styled-components';

export default styled.div``;

export const GuestTotalPriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.25rem;
`;

export const PriceContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.COLOR_CYAN_DARK};
  `}
  font-weight: bold;
  font-size: 1.5rem;
`;
