import { captureException } from '@sentry/node';
import { LOG_PREFIX, LOG_PREFIX_SEPARATOR } from './constants';

import * as _Types from '../jsdocTypedefs';

/** Class to handle cache persistor reporting, notifies of any errors, and can optionally
 * show information regarding cache persistence */
export default class CacheLogger {
  /**
   * @param {_Types.ApolloCachePersistorOptions} options
   * @returns {void}
   */
  constructor({ verbose }) {
    this.verbose = verbose;
  }

  /**
   * Generate the style used to highlight cache persistor messages in the console
   * @param {object} overrideStyles Object with standard style overrides
   * @returns {string} CSS string
   */
  getLogStyle = (overrideStyles) => {
    const mainStyles = {
      color: 'white',
      background: '#49bf55',
      padding: '2px 4px',
    };
    const overriddenStyles = {
      ...mainStyles,
      ...overrideStyles,
    };
    return Object.entries(overriddenStyles).map(([k, v]) => `${k}:${v}`).join(';');
  }

  /**
   * Log an info message to the console
   * @param {string} message Message to log to the console
   * @param {object} replacements Object with string keys to replace in the message
   * @returns {void}
   */
  log = (message, replacements = {}) => {
    if (this.verbose) {
      let templatedMessage = message;
      Object.keys(replacements).forEach((key) => {
        templatedMessage = templatedMessage.replace(`{{${key}}}`, replacements[key]);
      });
      console.info(`%c${LOG_PREFIX}`, this.getLogStyle(), LOG_PREFIX_SEPARATOR, templatedMessage);
    }
  }

  /**
   * Log an error to the console and optionally Sentry
   * @param {string} message Error message
   * @param {boolean} shouldLog Whether to log this error to Sentry
   * @param {Object} extra Extras for logging to Sentry
   * @returns {void}
   */
  error = (message, shouldLog = false, extra = {}) => {
    if (shouldLog) {
      captureException(message, { extra });
    }
    const consoleStyling = this.getLogStyle({
      background: '#ed594e',
    });
    console.info(`%c${LOG_PREFIX}`, consoleStyling, LOG_PREFIX_SEPARATOR, message);
  }
}
