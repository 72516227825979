import compressor from 'lzutf8';

import Storage from './Storage';
import CacheLogger from './CacheLogger';
import ApolloCache from './ApolloCache';

import { COMPRESSION_ENCODING, MESSAGES } from './constants';

import * as _Types from '../jsdocTypedefs';

/** Class to handle the actual persisting/restoring actions to and from storage */
export default class Persistor {
  /**
   * @param {_Types.ApolloCachePersistorOptions} options
   * @returns {void}
   */
  constructor(options) {
    const { cache, enableCompression, storageLimit } = options;

    this.apolloCache = new ApolloCache(options);
    this.storage = new Storage(options);
    this.logger = new CacheLogger(options);

    this.providedCache = cache;
    this.storageLimit = storageLimit;
    this.enableCompression = enableCompression;
  }

  persist = () => {
    if (!this.apolloCache) return false;
    // compress cache here
    const formattedCache = this.formatCacheForStorage();
    const cacheSize = formattedCache.length;
    this.logger.log(MESSAGES.PERSISTED_CACHE.ATTEMPTING, { cacheSize: cacheSize.toLocaleString() });
    // Don't store over our limit as we could potentially
    // exceed our storage quota
    if (cacheSize > this.storageLimit) {
      this.logger.log(MESSAGES.PERSISTED_CACHE.FAILED_QUOTA);
      return false;
    }
    try {
      this.storage.write(formattedCache);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  /**
   * Attempts to restore InMemoryCache from provided storage solution
   * @returns {boolean} Success flag
   */
  restore = () => {
    try {
      // Read the compressed Base-64 encoded cache
      const encodedCache = this.storage.read();
      if (!encodedCache) return false;
      const parsedCache = this.parseCacheFromStorage(encodedCache);
      this.providedCache.restore(parsedCache);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  /**
   * Attempts to delete the local persisted cache
   * @returns {boolean} success flag
   */
  purge = () => {
    try {
      this.storage.purge();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  formatCacheForStorage = () => {
    // Filter out all disallowed typenames
    const filteredCache = this.apolloCache.filter();
    const stringifiedCache = JSON.stringify(filteredCache);
    // Compress if it's enabled
    if (this.enableCompression) {
      return compressor.compress(stringifiedCache, {
        outputEncoding: COMPRESSION_ENCODING,
      });
    }
    return stringifiedCache;
  }

  parseCacheFromStorage = (encodedCache) => {
    if (this.enableCompression) {
      const decompressedCache = compressor.decompress(encodedCache, {
        inputEncoding: COMPRESSION_ENCODING,
      });
      return JSON.parse(decompressedCache);
    }
    return JSON.parse(encodedCache);
  };
}
