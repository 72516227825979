import gql from 'graphql-tag';

export default gql`
  query configuration {
    configuration @rest(
      type: "Configuration",
      path: "configuration"
    ) {
      allowAmendmentRestrictionTypes
      amendAndCancelExtraRestrictions
      availabilityMaxDate
      basketStates
      bookingAmendmentRestrictionTypes
      bookingConfirmationImage
      bookingTimelines
      cancellationReasons
      communityGuidelinesLink
      configurableValues {
        emailConfirmationModalTimeToCloseMs
        minLocationSearchRadiusMilesDecimal
        showALongWayWhenGreaterThanDistanceDecimal
        showOneDecimalPlaceWhenLessThanDistanceDecimal
        campingUpsellIsEnabled
      }
      defaultCampsiteLogoImage
      defaultMaxVehicleOverallHeight
      defaultMaxVehicleOverallLength
      errorCodeTypes
      extrasInfo
      fareTypes
      homePageLink
      internationalPhoneAccessCodes
      itxPackageRules
      joinNowLink
      liveChat
      loginUrlLink
      membershipRenewalLink
      membershipContent
      membershipTypes
      overlappingSitesTypes
      overseasTravelInsuranceImageUrl
      partyMemberTypes
      ports
      preBookingCheckIssueTypes
      products
      quoteChangeTypes
      quoteComponentTypes
      quoteMembershipTypes
      redPennantInsuranceGetAQuoteLink
      siteNightVoucherInfo {
        leftAltContent
        leftContent
        extraName
        desktopImage
        id
        mobileImage
        quantityMaximum
        quantityMinimum
        rightContent
        searchListingImage
        titleAltText
        titleText
        unitCost
      }
      specialRequests
      suppliers
      termsAndConditionsLink
      towTypes
      vehicleTypes
      eventTypes
      partyConfig
      partyMemberStayTypes
    }
  }
`;
