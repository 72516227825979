import gql from 'graphql-tag';
import { getLocationCoordinatesInStorage } from '../../components/Search/CampsiteListAndPriceQueryHelpers';
import { CAMPSITE_SORT_TYPES } from '../../lib/campsiteSearch';
import connectionType from '../../lib/connectionType';
import buildPath from '../../lib/helpers/restLink';

export function shouldSkipCampsiteListing({
  bottomRightLat,
  bottomRightLon,
  topLeftLat,
  topLeftLon,
}) {
  return !bottomRightLat || !bottomRightLon || !topLeftLat || !topLeftLon;
}

export function parseDates(query = {}) {
  const { start, end } = query;
  if (!start || !end) return {};
  return { start, end };
}

function normalizeListingAndPriceVariables(incomingVariables) {
  let { searchLat, searchLon } = incomingVariables;
  if (incomingVariables.sortType === CAMPSITE_SORT_TYPES.DISTANCE_ASC) {
    if (!searchLat || !searchLon) {
      const searchLatSearchLon = getLocationCoordinatesInStorage();
      searchLat = searchLatSearchLon?.searchLat;
      searchLon = searchLatSearchLon?.searchLon;
    }
  }

  // This block prevents any searches with distance sort type that are missing the required
  // search coordinates. This stops requests 400'ing and returns results.
  // Defaults back to sorting by name for safety as it's valid for both campsite and
  // location searches.
  const isDistanceSearch = incomingVariables.sortType === CAMPSITE_SORT_TYPES.DISTANCE_ASC;
  const isValidDistanceSearch = isDistanceSearch && searchLat && searchLon;
  const isValidSortType = !isDistanceSearch || isValidDistanceSearch;

  const sortType = isValidSortType ? incomingVariables.sortType : CAMPSITE_SORT_TYPES.SITE_NAME;

  const variables = {
    bottomRightLat: incomingVariables.bottomRightLat,
    bottomRightLon: incomingVariables.bottomRightLon,
    features: incomingVariables.features || [],
    page: incomingVariables.page || 0,
    pageSize: incomingVariables.pageSize || connectionType(),
    searchLat: searchLat || incomingVariables.searchLat || undefined,
    searchLon: searchLon || incomingVariables.searchLon || undefined,
    topLeftLat: incomingVariables.topLeftLat,
    topLeftLon: incomingVariables.topLeftLon,
    types: incomingVariables.types || [],
    sortType: sortType ?? CAMPSITE_SORT_TYPES.SITE_NAME,
  };

  return variables;
}

export function normalizeListingVariablesForConsistentPriceCache(incomingVariables) {
  return {
    ...normalizeListingAndPriceVariables(incomingVariables),
    pathBuilder: buildPath('campsite/search'),
    skip: incomingVariables.skip,
    suggestedUkItineraryId: incomingVariables.suggestedUkItineraryId || '',
  };
}

export function normalizePriceVariablesForConsistentPriceCache(incomingVariables) {
  return {
    ...normalizeListingAndPriceVariables(incomingVariables),
    end: incomingVariables.end || '',
    pathBuilder: buildPath('campsite/prices'),
    skip: shouldSkipCampsiteListing(incomingVariables),
    start: incomingVariables.start || '',
  };
}

export default gql`
  query Prices(
    $start: String,
    $end: String,
    $path: any,
    $topLeftLat: Float!,
    $topLeftLon: Float!,
    $bottomRightLat: Float!,
    $bottomRightLon: Float!,
    $features: [Float],
    $types: [Float],
    $page: Int!,
    $pageSize: Int!,
    $sortType: Int!,
    $searchLat: Float,
    $searchLon: Float,
  ) {
    prices (
      start: $start,
      end: $end,
      topLeftLat: $topLeftLat,
      topLeftLon: $topLeftLon,
      bottomRightLat: $bottomRightLat,
      bottomRightLon: $bottomRightLon,
      features: $features,
      types: $types
      page: $page,
      pageSize: $pageSize,
      sortType: $sortType,
      searchLat: $searchLat,
      searchLon: $searchLon,
    ) @rest(
      type: "Prices",
      pathBuilder: $pathBuilder
    ) {
      count
      data
    }
  }
`;
