/**
 * These should match the values passed in the configuration in campsiteTypes
 */
const UK_CLUB_SITES = 'UK_CLUB_SITES';
const UK_AFFILIATED_SITES = 'UK_AFFILIATED_SITES';
const CERTIFICATED_LOCATIONS = 'CERTIFICATED_LOCATIONS';
const OVERSEAS_SITE = 'OVERSEAS_SITE';
const UNKNOWN = 'UNKNOWN';
const POINT_OF_INTEREST = 'POINT_OF_INTEREST';
const OVERSEAS_TOUR = 'OVERSEAS_TOUR';

export const keys = {
  UK_CLUB_SITES,
  UK_AFFILIATED_SITES,
  CERTIFICATED_LOCATIONS,
  OVERSEAS_SITE,
  UNKNOWN,
  POINT_OF_INTEREST,
  OVERSEAS_TOUR,
};

export const values = {
  [UK_CLUB_SITES]: 'UK Club site',
  [UK_AFFILIATED_SITES]: 'UK Affiliated site',
  [CERTIFICATED_LOCATIONS]: 'Certificated location',
  [OVERSEAS_SITE]: 'Overseas site',
  [UNKNOWN]: 'Unknown',
  [POINT_OF_INTEREST]: 'Point Of Interest',
  [OVERSEAS_TOUR]: 'Tour',
};

export const ids = {
  [UNKNOWN]: 0,
  [UK_CLUB_SITES]: 1,
  [UK_AFFILIATED_SITES]: 2,
  [CERTIFICATED_LOCATIONS]: 3,
  [OVERSEAS_SITE]: 4,
  [POINT_OF_INTEREST]: 5,
  [OVERSEAS_TOUR]: 6,
};

export const defaultUKTypes = [
  ids.UK_AFFILIATED_SITES,
  ids.UK_CLUB_SITES,
];

export const defaultOSTypes = [
  ids.OVERSEAS_SITE,
];

export const defaultTypes = [
  ids.UK_CLUB_SITES,
  ids.OVERSEAS_SITE,
];

export const nonFilterableTypes = [
  ids.UNKNOWN,
  ids.POINT_OF_INTEREST,
  ids.OVERSEAS_TOUR,
];

export const defaultFilterableTypes = [
  ids.UK_CLUB_SITES,
  ids.UK_AFFILIATED_SITES,
  ids.CERTIFICATED_LOCATIONS,
  ids.OVERSEAS_SITE,
];
