import gql from 'graphql-tag';

import * as _Types from '../jsdocTypedefs';

/** Class which handles the provided Apollo InMemoryCache and exposes some
 * general methods which allow us to interact with the cache */
export default class ApolloCache {
  /**
   * Instantiates a new ApolloCache instance
   * @param {_Types.ApolloCachePersistorOptions} options
   * @returns {void}
   */
  constructor(options) {
    const { cache, disallowedTypenames } = options;

    this.providedCache = cache;
    this.disallowedTypenames = disallowedTypenames;
  }

  /**
   * Extracts a JSON-type cache from Apollo Cache
   * @returns {Object}
   */
  extract = () => {
    if (!this.providedCache) return null;
    return this.providedCache.extract();
  }

  /**
   * Removes the chosen typenames from the cache
   * @returns {Object} The filtered cache
   */
  filter = () => {
    const extractedCache = this.extract();
    const filteredCache = {};
    Object.keys(extractedCache ?? {}).forEach((key) => {
      const cacheValue = extractedCache[key];
      // eslint-disable-next-line no-underscore-dangle
      if (!this.disallowedTypenames.includes(cacheValue.__typename)) {
        filteredCache[key] = cacheValue;
      }
    });
    return filteredCache;
  }

  /**
   * Executes the watch callback when the Apollo cache updates
   * @param {Function} callback
   * @returns {void}
   */
  watchCache = (callback) => {
    this.providedCache.watch({
      query: gql`
        query WatchAllQueries {
          __typename
        }
      `,
      callback,
    });
  }
}
