import { stringify } from 'query-string';
import moment from 'moment';
import { groupBy } from 'lodash';
import { v4 as uuid } from 'uuid';
import { isAfter, isBefore, isValid } from 'date-fns';

import PRODUCT_TYPES from '../../config/products';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_INPUT } from '../../config/locale';
import { CHILD, ADULT, INFANT } from '../../lib/partyMemberTypes';
import { getAgeRange } from './GuestDetails';
import { formatToHyphenFormat } from '../../lib/format';

import * as _Types from '../../lib/jsdocTypedefs';

export const FALLBACK_CHILD_MAX_AGE = 17;
const VALID_DATE_LENGTH = DATE_FORMAT_DEFAULT.length;

export function parseVariables(variables = {}, path = '') {
  const queryString = stringify(variables);
  const pathWithQuery = `${path}?${queryString}`;
  return {
    path: pathWithQuery,
    ...variables,
  };
}

export const buildPartyLabel = (
  label, minAge, maxAge,
) => `${label} (${minAge}${!maxAge ? '+' : ' - '}${maxAge || ''})`;

export const guestOptions = (configuration, isOverseas) => {
  const { products, partyMemberTypes } = configuration;
  const product = products.find(
    (productItem) => productItem.productCode ===
      (isOverseas ? PRODUCT_TYPES.CAMC_MEMBER_PRODUCT : PRODUCT_TYPES.CAMC_UK_PRODUCT),
  );

  const { partyConfig } = product;
  return partyConfig
    .filter((item) => item.fromAge >= 0)
    .map((partyConfigItem, key) => {
      const selectedPartyMemberType = partyMemberTypes.find(
        (partyMemberTypeItem) => partyMemberTypeItem.key === partyConfigItem.type,
      );
      const ageMin = (partyConfig[key + 1]?.toAge ?? 0) + (partyConfigItem.type === INFANT ? 0 : 1);
      const ageMax = key === 0 ? 0 : partyConfig[key].toAge ?? 0;

      return {
        id: partyConfigItem.type,
        type: partyConfigItem.type,
        label: buildPartyLabel(selectedPartyMemberType?.value, ageMin, ageMax),
        ageMin,
        ageMax,
      };
    });
};

export const getAge = (dateOfBirth, arrivalDate) => {
  const now = arrivalDate ? moment(arrivalDate) : moment(new Date());
  const dob = moment(dateOfBirth);
  now.set({
    hour: 1, minute: 0, second: 0, millisecond: 0,
  });
  dob.set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  });
  const isBirthdayOnArrival = now.date() === dob.date() && now.month() === dob.month();

  const roundFunc = isBirthdayOnArrival ? Math.round : Math.floor;
  return roundFunc(now.diff(dob, 'years'));
};

export const isDateValid = (date) => moment(date, DATE_FORMAT_DEFAULT, true).isValid();

export const getTypeByDateOfBirth = (dateOfBirth, arrivalTime, partyOptions, pitchConfig) => {
  const age = getAge(dateOfBirth, arrivalTime);
  const childConfig = partyOptions.find(option => option.type === CHILD);
  if (age > (pitchConfig.maxChildAge ?? childConfig?.ageMax)) {
    return ADULT;
  }
  if (age >= (pitchConfig.minChildAge ?? childConfig.ageMin)) {
    return CHILD;
  }
  return INFANT;
};

/**
 * Validates a date of birth for a specific type of guest
 * @param {string} dateOfBirth
 * @param {number} type
 * @param {_Types.PartyOptions} partyOptions
 * @param {string} arrivalDate
 * @param {_Types.PitchConfig} pitchConfig
 * @returns {{
 *  guestType: number
 *  isValidAge: boolean
 *  age: number | null
 * }}
 */
export const validateDateOfBirth = (dateOfBirth, type, partyOptions, arrivalDate, pitchConfig) => {
  const partyOption = partyOptions.find(option => option.type === Number(type));
  const childOption = partyOptions.find(option => option.type === CHILD);
  if (type === ADULT) {
    return { isValidAge: true };
  }
  if (!partyOption) {
    return { guestType: type, isValidAge: false };
  }

  const age = getAge(dateOfBirth, arrivalDate);

  const isChildOrInfantType = type === CHILD || type === INFANT;

  let minAge = partyOption.ageMin;
  let maxAge = partyOption.ageMax;

  if (type === ADULT) {
    minAge = pitchConfig?.adultFromAge ?? minAge;
  }

  if (type === CHILD) {
    minAge = pitchConfig?.childFromAge ?? minAge;
    maxAge = pitchConfig?.childToAge ?? maxAge;
  }

  if (type === INFANT) {
    minAge = partyOption.ageMin ?? minAge;
    maxAge = pitchConfig?.minChildAge ? (pitchConfig?.minChildAge - 1) : maxAge;
  }

  const isValidAge = isChildOrInfantType ?
    (age >= minAge && age <= maxAge) : age >= minAge;

  let guestType = type;
  if (!isValidAge) {
    if (age < (pitchConfig?.childFromAge ?? childOption.ageMin)) {
      guestType = INFANT;
    } else if (age > (pitchConfig?.childToAge ?? childOption.ageMax)) {
      guestType = ADULT;
    } else {
      guestType = CHILD;
    }
  }
  return { guestType, isValidAge, age };
};

/**
 * Generates a re-usable party option object from a guest object
 * @param {_Types.PartialPartyOption} guest
 * @returns {_Types.PartyOption}
 */
const createPartyOption = ({
  defaultValue = 0,
  fromAge = null,
  label = '',
  min = 0,
  toAge = null,
  type = null,
}) => {
  function formatDescription(option = { fromAge, toAge }, description) {
    if (typeof description !== 'undefined' || typeof (option.fromAge) === 'undefined') return description;
    return option.toAge ? `(ages ${option.fromAge} - ${option.toAge})` : `(aged ${option.fromAge}+)`;
  }

  return {
    defaultValue,
    description: formatDescription(),
    fromAge,
    key: type,
    label,
    min,
    toAge,
    type,
    value: 0,

    setDescription(description) {
      this.description = formatDescription(this, description);
      return this;
    },

    setFromAge(age) {
      if (age === null) return this;
      this.fromAge = age;
      return this;
    },

    setToAge(age) {
      if (age === null) return this;
      this.toAge = age;
      return this;
    },

    setValue(value) {
      this.value = value;
      return this;
    },
  };
};

/**
 * Creates the possible member options for the <PartySelect /> component
 * @param {_Types.PartyConfig}       partyConfig
 * @param {_Types.PartyMemberTypes}  partyMemberTypes
 * @param {_Types.PitchConfig}       pitchConfig
 * @returns {_Types.PartyOptions}
 */
export const createPartyOptions = (
  partyConfig,
  partyMemberTypes = [],
  pitchConfig,
) => {
  const partyOptions = partyConfig.map(option => createPartyOption({
    ...option,
    label: partyMemberTypes.get(option.type) || '',
  }));
  if (pitchConfig) {
    const {
      adultFromAge, childFromAge, childToAge,
    } = pitchConfig;
    // Configure adult
    partyOptions[0]
      .setFromAge(adultFromAge)
      .setDescription();

    // Configure child
    partyOptions[1]
      .setFromAge(childFromAge)
      .setToAge(childToAge)
      .setDescription();

    // Configure infant
    partyOptions[2]
      .setToAge(childFromAge - 1)
      .setDescription();
  }
  return partyOptions;
};

/* Constructs an object of the age ranges for each party member type

  @returns {
    0: {
      name: 'Adult',
      range: [18, undefined]
    },
    ...
  }
*/
export const getBoundingAgesByMemberType = (configuration, productCode) => {
  const product = configuration.products.find((prod) => prod.productCode === productCode);
  if (!product) return null;
  const { partyMemberTypes } = configuration;
  const { partyConfig } = product;

  const ageRanges = partyMemberTypes.reduce((acc, memberType) => {
    acc[memberType.key] = {
      name: memberType.value,
      range: getAgeRange(partyConfig, memberType.key),
    };
    return acc;
  }, {});

  return ageRanges;
};

export const partySelectToMembersArray =
  (partySelectInput) => partySelectInput.map((input) => ({ ...input, type: input.type }));

/**
 * Helper which generates the party options that the <PartySelect /> component
 * uses
 * @param {any} configuration  Standard config object
 * @param {number} productCode    Current product code
 * @param {number} maxChildAge    Maximum child age
 * @param {number} minChildAge    Minimum child age
 * @returns {any}
 */
export const getPartySelectOptions = (configuration, productCode, maxChildAge, minChildAge) => {
  const { partyMemberTypes, products } = configuration;

  const product = products.find(
    productItem => productItem.productCode === Number(productCode),
  );
  if (!product?.partyConfig) {
    return null;
  }

  const { partyConfig } = product;

  // Create a map of party member types
  const partyMemberTypesMap = new Map();
  partyMemberTypes.forEach(({ key, value }) => partyMemberTypesMap.set(key, value));

  const childToAge = maxChildAge || partyConfig.find(({ type }) => type === CHILD).toAge;
  const childFromAge = minChildAge ?? partyConfig.find(({ type }) => type === CHILD).fromAge;
  const adultFromAge = childToAge !== null ? childToAge + 1 : null;

  const pitchConfig = {
    childToAge, childFromAge, adultFromAge, minChildAge,
  };

  const partyOptions = createPartyOptions(partyConfig, partyMemberTypesMap, pitchConfig);

  return partyOptions;
};

/**
 * Returns all guests from a list by a specific type
 * @param {_Types.Guest[]}   guestList   The list with 'type' fields
 * @param {number}          type        The type to return
 * @returns {_Types.Guest[]}
 */
export const getGuestsByType =
  (guestList, type) => guestList?.filter((guest) => guest.type === type) ?? [];

/**
 * Returns a party breakdown by guest type
 * @param {object[]} guestList
 * @returns {
 *  0: [{}],
 *  1: [{}],
 *  2: [{}],
 * }
 */
export const guestListBreakdown = (guestList) => {
  const adults = getGuestsByType(guestList, ADULT);
  const children = getGuestsByType(guestList, CHILD);
  const infants = getGuestsByType(guestList, INFANT);

  return {
    [ADULT]: adults,
    [CHILD]: children,
    [INFANT]: infants,
  };
};

/**
 * Formats the group format used by the Part Stay Guests picker
 * to an un-grouped format the BE can accept
 * @param {object[]}  groups   Formatted part stay guest groups
 * @returns {object[]}
 */
export const formatPartStayGroupsForQuote =
(groups) => groups.map((group) => group.guests.map((guest) => ({
  stayStart: group.start,
  stayEnd: group.end,
  type: guest.type,
}))).flat();

/* Format an object in shape:
  [ {guestId: X}, {guestId: Y} ]
  To a format the partySelect component can use
*/
const guestsToPartySelect = (guestGroup) => guestGroup.map((guest) => ({
  ...guest,
  type: guest.type,
  age: null,
  personId: '',
  __typename: 'PartyMember',
}));

/**
 * Formats the guests we get back from the quote to a format the partySelect can handle
 * @param {_Types.QuoteGuest[]} guests
 * @returns {_Types.GuestGroup[]}
 */
export const formatPartStayGuestsQuoteToGroups = (guests) => {
  // First group guests by date
  const output = groupBy(guests, (guest) => `${guest.stayStart}-${guest.stayEnd}`);
  // Then format correctly so the components can handle it
  return Object.keys(output).map((key) => {
    const guestData = output[key];
    const guestList = guestData.map((guest) => {
      const { dateOfBirth, type } = guest;
      return { type, dateOfBirth };
    });
    const groupPrice = guestData.reduce((acc, current) => acc + current.price, 0);
    const sampleGuest = guestData[0];
    return {
      start: sampleGuest.stayStart,
      end: sampleGuest.stayEnd,
      guests: guestList,
      unsanitizedGuests: guestsToPartySelect(guestList),
      id: uuid(),
      price: groupPrice,
    };
  });
};

/**
 * Extracted helper for validating the DOBs of children
 * for overseas part-stay guests
 * @param {string}  dateOfBirth The child's DOB
 * @param {boolean} isOverseas  Whether we're OS or not
 * @param {number}  childToAge  Max child age, usually from config
 * @returns {boolean}
 */
export const isChildDOBValid = (dateOfBirth, isOverseas, childToAge = FALLBACK_CHILD_MAX_AGE) => {
  if (!dateOfBirth) return false;
  if (!isOverseas) return true;
  // The minimum DOB a 'child' can have
  const minimumChildDob = moment().subtract(childToAge, 'years');
  const momentDob = moment(dateOfBirth, DATE_FORMAT_DEFAULT);
  if (momentDob.isBefore(minimumChildDob)) return false;
  return true;
};

/**
 * Helper to check if children DOBs are valid for OS part-stay guests
 * @param {_Types.Guest[]} groupMembers
 * @param {boolean}       isOverseas
 * @param {number}        childToAge    The max allowed child age
 * @returns {{
 *  allChildrenHaveDOBs: boolean
 *  areAllDobsValid: boolean
 * }}
 */
export const validateChildDOBs = (groupMembers, isOverseas, childToAge) => {
  if (!isOverseas) {
    return {
      allChildrenHaveDOBs: true,
      areAllDobsValid: true,
    };
  }
  const children = groupMembers.filter((member) => member.type === CHILD);
  const allChildrenHaveDOBs = children.every(({ dateOfBirth }) => !!dateOfBirth);
  // Check DOB validity
  const areAllDobsValid = children
    .map((child) => isChildDOBValid(child.dateOfBirth, isOverseas, childToAge))
    .every((valid) => !!valid);

  return { allChildrenHaveDOBs, areAllDobsValid };
};

/**
 * Extracted helper for validating and tweaking dates
 * for part-stay guests
 * @param {any} event    The DOM event returned by the datepicker
 * @param {string} minDate  String for the minimum allowed date
 * @param {string} maxDate  String for the maximum allowed date
 * @returns {string | null}
 */
export const validateDate = (event, minDate, maxDate) => {
  const dateType = event.target.name;
  let newDate = formatToHyphenFormat(
    event.target.value,
  );
  if (event.target.value?.length === VALID_DATE_LENGTH && isValid(new Date(newDate))) {
    if (isBefore(newDate, minDate) && dateType === 'start') {
      newDate = minDate;
    }
    if (isAfter(newDate, maxDate) && dateType === 'end') {
      newDate = maxDate;
    }
    return moment(new Date(newDate)).format(
      DATE_FORMAT_INPUT,
    );
  }
  return null;
};

/**
 * Small helper to get the maximum child age for a pitch
 * @param {_Types.PitchConfig} pitchConfig Config for the current pitch
 * @returns {number}
 */
export const getChildToAge = (pitchConfig) => pitchConfig.childToAge ?? FALLBACK_CHILD_MAX_AGE;

/**
 * Removes certain unnecessary fields from a list of objects for when
 * they are sent to GA
 * @param {_Types.Guest[]} guests
 * @returns {_Types.DataLayerGuest[]}
 */
export const formatGuestsForDataLayer = (guests) => guests.map((guest) => {
  const newGuest = { ...guest };
  delete newGuest.age;
  // eslint-disable-next-line no-underscore-dangle
  delete newGuest.__typename;
  return newGuest;
});
