import * as _Types from '../jsdocTypedefs';

/** Storage handler class to expose simple storage methods to other classes */
export default class Storage {
  /**
   * @param {_Types.ApolloCachePersistorOptions} options
   * @returns {void}
   */
  constructor(options) {
    const { store, storageKey } = options;
    this.storage = store;
    this.storageKey = storageKey;
  }

  /**
   * Stores the provided data item in long-term storage by a key
   * that's provided in the main constructor call
   * @param {unknown} data
   * @returns {any}
   */
  write = (data) => {
    if (!this.storage) return;
    this.storage.setItem(this.storageKey, data);
  }

  /**
   * Reads an item from long term storage
   * @returns {unknown | null}
   */
  read = () => {
    if (!this.storage) return null;
    return this.storage.getItem(this.storageKey);
  }

  /**
   * Removes an item from long term storage
   * @returns {void}
   */
  purge = () => {
    if (!this.storage) return;
    this.storage.removeItem(this.storageKey);
  }
}
