import gql from 'graphql-tag';

const GET_CROSSINGS_CONFIG = gql`
  query getCrossingsConfig {
    configurationCrossings @rest(type: "ConfigurationCrossings", path: "configuration/crossings") {
      defaultCrossingPreferredTime
      defaultCrossingZoomLevel
      defaultMaxCrossingAccomQuantity
      defaultMaxVehicleOverallHeightCrossing
      defaultMaxVehicleOverallLengthCrossing
      portZoneTypes
      routes
    }
  }
`;

export default GET_CROSSINGS_CONFIG;
