import { LOCATION_SEARCH_COORDINATES_IN_SESSION } from '../../lib/constants';
import PLACE_EVENT_TYPES, { SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import { getStorageItemList } from '../../lib/storage';

export function getPrices(data) {
  if (data && data.prices && data.prices.data) return data.prices;

  return {
    count: 0,
    data: [],
  };
}

export function getDefaultPlaceEvent(priceItem, eventTypeId, isOverseas) {
  const selectedEvent = priceItem.events?.find(
    e => e.eventType === Number(eventTypeId),
  );

  const overseasSingleEvent = priceItem?.events?.find(
    e => isOverseas && e.eventType === SINGLE_EVENT_TYPE.id,
  );

  const touringEvent = priceItem?.events?.find(
    e => e.eventType === PLACE_EVENT_TYPES.TOURING.id,
  );

  const firstEvent = priceItem?.events?.[0];

  return selectedEvent || overseasSingleEvent || touringEvent || firstEvent;
}

/**
 * Merge is used to merge api campsite listing items and api price items for
 * CampsiteListAndPriceQuery
 */
export function mergeListingAndPrices(listingItems, priceItems, eventTypeId, isOverseas) {
  if (!listingItems?.length) return [];
  if (!priceItems?.length) return listingItems;

  const results = listingItems.map((listingItem) => {
    const priceItem = priceItems.find(p => p.id === listingItem.id);
    if (!priceItem) return listingItem;

    const defaultEvent = getDefaultPlaceEvent(priceItem, eventTypeId, isOverseas);
    if (!defaultEvent) return listingItem;

    return {
      ...listingItem,
      memberPrice: defaultEvent.cheapestMemberPrice, // spliced from prices api
      offers: defaultEvent.offers, // spliced from prices api
    };
  });

  return results;
}

/**
 * Merge is used to merge api location items and api price items for MapMarkers
 */
export function mergeLocationsListingAndPrices(
  locationItems, listingItems, priceItems, eventTypeId, isOverseas,
) {
  if (!locationItems?.length) return [];
  if (!priceItems?.length) return locationItems;

  const openDatesData = {};
  if (listingItems?.length) {
    // map openDates against id ready for
    listingItems.forEach(listingItem => {
      listingItem.placeEvent.forEach((event) => {
        openDatesData[event.id] = event.openDates;
      });
    });
  }

  const results = locationItems.map((locationItem) => {
    const priceItem = priceItems.find(p => p.id === locationItem.id);
    if (!priceItem) return locationItem;

    const defaultEvent = getDefaultPlaceEvent(priceItem, eventTypeId, isOverseas);
    if (!defaultEvent) return locationItem;

    return {
      ...locationItem,
      ...priceItem,
      defaultEvent: {
        ...defaultEvent,
        openDates: openDatesData[defaultEvent.id] || defaultEvent.openDates,
      },
    };
  });

  return results;
}

export function shouldSkipCampsiteListing({
  bottomRightLat,
  bottomRightLon,
  topLeftLat,
  topLeftLon,
}) {
  return !bottomRightLat || !bottomRightLon || !topLeftLat || !topLeftLon;
}

export function shouldSkipCampsiteListingIfInvalidDates({
  bottomRightLat,
  bottomRightLon,
  topLeftLat,
  topLeftLon,
  start,
  end,
}) {
  const noStartDateButEndDate = (!start || start === 'null') && !!end;
  const noEndDateButStartDate = (!end || end === 'null') && !!start;
  const sameStartAndEndDates = !!start && start === end;

  return (
    !bottomRightLat ||
    !bottomRightLon ||
    !topLeftLat ||
    !topLeftLon ||
    sameStartAndEndDates ||
    noStartDateButEndDate ||
    noEndDateButStartDate
  );
}

export function getCampsiteListing(data) {
  if (data && data.campsiteListing && data.campsiteListing.data) return data.campsiteListing;

  return {
    aggregations: [],
    count: 0,
    data: [],
  };
}

export function getLocationCoordinatesInStorage() {
  if (typeof window === 'undefined') return null;

  // utilize location.search here instead of having to pass through router.query
  const urlSearchParams = new URLSearchParams(window.location.search);
  const location = urlSearchParams.get('location');

  const coords = getStorageItemList(LOCATION_SEARCH_COORDINATES_IN_SESSION, location);
  if (coords) {
    return { searchLat: coords.lat, searchLon: coords.lng };
  }
  return null;
}
