import { debounce } from 'lodash';

import ApolloCache from './ApolloCache';
import Persistor from './Persistor';
import CacheLogger from './CacheLogger';

import * as _Types from '../jsdocTypedefs';

import { MESSAGES, PERSISTOR_DEFAULTS } from './constants';

/** Publicly accessible class for handling cache persisting */
class CachePersistor {
  /**
   * @param {_Types.ApolloCachePersistorOptions} input
   * @returns {void}
   */
  constructor(input) {
    // User input + defaults
    const options = {
      ...PERSISTOR_DEFAULTS,
      ...input,
    };

    const {
      cache,
      persistDebounceTimeout,
      storageKey,
      store,
      storageLimit,
    } = options;

    // Class Fields
    this.logger = new CacheLogger(options);

    if (!cache) {
      this.logger.error(MESSAGES.INIT_FAILED.MISSING_CACHE);
    }

    if (!store) {
      this.logger.error(MESSAGES.INIT_FAILED.MISSING_STORE);
    }

    this.apolloCache = new ApolloCache(options);
    this.persistor = new Persistor(options);
    this.storageKey = storageKey;
    this.storageLimit = storageLimit;
    this.debounceTimer = persistDebounceTimeout;

    // Method bindings
    this.debouncedPersist.bind(this);
  }

  /**
   * Persists the Apollo ApolloCache to localStorage
   * @returns {void}
   */
  persistCache = () => {
    if (!this.apolloCache) return;
    const success = this.persistor.persist();
    if (success) {
      this.logger.log(MESSAGES.PERSISTED_CACHE.SUCCESS);
      return;
    }
    this.logger.error(MESSAGES.PERSISTED_CACHE.FAILED_GENERAL, true);
  };

  /**
   * Debounced persist cache call so we collect calls over a time period
   * @returns {void}
   */
  debouncedPersist = debounce(() => {
    this.persistCache();
  // TODO - Have this timer be configurable, currently doesn't work if using a passed in value
  }, 2000);

  /**
   * Watches the apollo cache and runs the persistor whenever there's a change
   *
   * Debounced so we don't spam when lots of queries update (e.g. page load)
   * @returns {void}
   */
  watchCache = () => {
    this.apolloCache.watchCache(this.debouncedPersist);
  };

  /**
   * Restores the Apollo InMemoryCache from the persisted cache
   * stored in long-term storage
   * @returns {void}
   */
  restore = () => {
    const success = this.persistor.restore();
    if (success) {
      this.logger.log(MESSAGES.RESTORED_CACHE.SUCCESS);
      return;
    }
    this.logger.error(MESSAGES.RESTORED_CACHE.FAILED);
  };

  /**
   * Public method to allow purging of persisted cache
   * Currently only used following a release
   * @returns {void}
   */
  purge = () => {
    const success = this.persistor.purge();
    if (success) {
      this.logger.log(MESSAGES.PURGED_CACHE.SUCCESS);
      return;
    }
    this.logger.error(MESSAGES.PURGED_CACHE.FAILED);
  };
}

export default CachePersistor;
