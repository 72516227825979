import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Label, Select } from '../ui/Form';
import { Col, Row } from '../ui/Grid';

import { partyConfig } from '../../config/personType';
import { rangeFrom } from '../PartyForm/PartyAges';

import StyledGuestDetails from './GuestDetails.style';

import { dictionaryItem } from '../../hocs/withDictionary';

import * as _Types from '../../lib/jsdocTypedefs';

const propTypes = {
  age: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  personId: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
};

const defaultProps = {
  age: null,
};

/**
 * @summary Takes a party type and returns the min and max age
 * @param {_Types.PartyConfig} options
 * @param {number[]} type
 * @returns {number[]} The min and max age for that passenger type
 */
export function getAgeRange(options = [], type) {
  const { fromAge, toAge } = options.find(opt => opt.type === type);
  return [fromAge, toAge];
}

class GuestDetails extends PureComponent {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  handleChange = (e) => {
    const guest = {
      age: this.props.age,
      personId: this.props.personId,
      type: this.props.type,
    };

    guest[e.target.name] = e.target.value;

    this.props.onChange(guest);
  }

  render() {
    const ageRange = getAgeRange(partyConfig, this.props.type);
    const ageOptions = rangeFrom(...ageRange);

    const guest = {
      age: this.props.age,
      personId: this.props.personId,
      type: this.props.type,
    };

    return (
      <StyledGuestDetails>
        <Row>
          <Col>
            <Label>Guest {this.props.index + 1}</Label>
            <Select
              block
              name="type"
              onChange={this.handleChange}
              value={this.props.type}
              type="number"
            >
              <option value={0}>Adult</option>
              <option value={1}>Child</option>
              <option value={2}>Infant</option>
            </Select>
          </Col>
          <Col>
            {guest.type !== 0 &&
              <Fragment>
                <Label dictionary={dictionaryItem('GuestDetails', 'ChildAge')} />
                <Select
                  name="age"
                  onChange={this.handleChange}
                  value={this.props.age}
                  type="number"
                >
                  {ageOptions.map(opt => (
                    <option key={opt} value={opt}>{opt}</option>
                  ))}
                </Select>
              </Fragment>
            }
          </Col>
        </Row>
      </StyledGuestDetails>
    );
  }
}

export default GuestDetails;
