const BYTES_PER_MB = 1024 * 1024;

export const PERSISTOR_DEFAULTS = {
  // Limit to which we won't attempt to store the cache in long-term storage
  storageLimit: 4 * BYTES_PER_MB,
  // Name to store data under in long-term storage
  storageKey: 'INSPIRETEC.apolloPersistedCache',
  // Bool to decide if we log persistor status to the console
  verbose: true,
  // Ms delay for how long we debounce before persisting the cache
  persistDebounceTimeout: 2000,
  // Which __typenames to exclude from persistence
  disallowedTypenames: ['Prices', 'CampsiteListing', 'Campsite'],
  // Whether persisted cache compression is enabled
  enableCompression: false,
};

export const MESSAGES = {
  INIT_FAILED: {
    MISSING_CACHE: 'Param `cache` must be provided',
    MISSING_QUERY: 'Param `store` must be defined',
  },
  PERSISTED_CACHE: {
    SUCCESS: 'Persisted cache successfully',
    FAILED_GENERAL: 'Failed cache persistence',
    FAILED_QUOTA: 'Failed cache persistence - Quota Exceeded',
    ATTEMPTING: 'Attempting to persist cache of size {{cacheSize}} bytes',
  },
  RESTORED_CACHE: {
    SUCCESS: 'Cache Restore Success',
    FAILED: 'Cache Restore Failed',
  },
  PURGED_CACHE: {
    SUCCESS: 'Cache Purge Success',
    FAILED: 'Cache Purge Failed',
  },
};

// The encoding the compression uses to store the result
// (and retrieve the data). Current set to Base64 as this
// produces a string, which is easy to integrate with
// storage areas (e.g. localStorage)
export const COMPRESSION_ENCODING = 'Base64';

export const LOG_PREFIX = '[Cache-Persistor]';
export const LOG_PREFIX_SEPARATOR = '-';
