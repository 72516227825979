import getUuid from 'uuid-by-string';
import { isValid } from 'date-fns';

import GET_CONFIGURATION from '../config/graphql/getConfiguration';
import { ids as campsiteTypeIds } from '../config/campsiteTypes';
import quoteMembershipTypes, {
  getENumWithValue,
  isMembershipInBasket,
} from '../config/quoteMembershipTypes';
import routes from '../constants/routes';
import GET_CROSSINGS_CONFIG from '../config/graphql/getCrossingsConfig';
import { formatGuestsForDataLayer } from '../components/Guests/guestHelpers';

let singletonInstance;
const uniqueSymbol = Symbol('singleton');

const brand = {
  CAMC: 'Caravan Club',
};

const category = {
  CROSSINGS: 'Crossing',
  SITES: 'Overseas',
  OVERSEAS: 'Overseas',
  UK: 'UK',
  MEMBERSHIP: 'Membership',
};

const subCategory = {
  EXTRA: 'Overseas Site Night Voucher',
  CAMPSITE: 'Campsite',
  VOUCHER: 'Voucher',
  CROSSINGS: 'Crossing',
  UK_ITINERARY: 'UK Itinerary',
  EUROPEAN_TOUR: 'European Tour',
};

const event = {
  ADD_TO_CART: 'addToCart',
  CHECKOUT: 'checkout',
  IMPRESSIONS_PUSHED: 'impressionsPushed',
  PAGE_META_DATA: 'pageMetaData',
  PART_STAY_GUESTS: 'partStayGuests',
  PRODUCT_CLICK: 'productClick',
  PRODUCT_DETAIL: 'productDetail',
  PROMO_CLICK: 'promoClick',
  PROMO_VIEW: 'promoView',
  PURCHASE: 'purchase',
  REMOVE_FROM_CART: 'removeFromCart',
};

const list = {
  SITES: 'sites',
  CROSSINGS: 'crossings',
  UK_ITINERARIES: 'uk itineraries',
  EUROPEAN_TOURS: 'european tours',
};

const labels = {
  na: 'not available',
  currencyCode: 'GBP',
};

const MEMBERSHIP_TYPE = {
  3: 'Continuous',
  4: 'One-off',
};

const MEMBERSHIP_MODE = {
  FAMILY: 'Family',
  LEAD: 'Lead',
  JOINT: 'Joint',
};

const MEMBERSHIP_ID = '294053';
const MEMBERSHIP_NAME = 'Annual Membership';
const MEMBERSHIP_SOURCE = 'Product Page';

const bespokeProductIds = {
  OVERSEAS_SITE_NIGHT_VOUCHERS: getUuid('Overseas Site Night Voucher'),
};

const getIdFromString = (str) => getUuid(str);

class DataLayerManager {
  static get instance() {
    if (!singletonInstance) singletonInstance = new DataLayerManager(uniqueSymbol);

    return singletonInstance;
  }

  pushHistory = {}

  // workaround for static properties that are available internally to both
  // static and instance methods as well as externally via the class.
  bespokeProductIds = bespokeProductIds;

  category = category;

  event = event;

  labels = labels;

  subCategory = subCategory;

  membershipType = MEMBERSHIP_TYPE;

  membershipMode = MEMBERSHIP_MODE;

  constructor(symbol) {
    if (symbol !== uniqueSymbol) throw new Error('Cannot construct singleton');
  }

  static getLastDataLayerEventType() {
    let eventType;

    if (window.dataLayer && window.dataLayer.length) {
      const lastItem = window.dataLayer.slice(-1).pop();
      if (lastItem.event) {
        eventType = lastItem.event;
      }
    }

    return eventType;
  }

  static getCrossingPrettyName(departurePortCode, arrivalPortCode, ports) {
    const departurePort = DataLayerManager.findPortViaPortCode(departurePortCode, ports);
    const arrivalPort = DataLayerManager.findPortViaPortCode(arrivalPortCode, ports);

    const departurePortName = departurePort ? departurePort.name : '';
    const arrivalPortName = arrivalPort ? arrivalPort.name : '';

    return `${departurePortName} - ${arrivalPortName}`;
  }

  static getSupplierPortName(supplierCode, suppliers) {
    const supplier = suppliers.find(s => (
      s.supplierCodes ? s.supplierCodes.indexOf(supplierCode) > -1 : ''
    ));

    return supplier ? supplier.name : `Supplier Name ${labels.na}`;
  }

  static getVehicleName(vehicleType, vehicleTypes) {
    const foundVehicle = vehicleTypes.find(vehicle => vehicle.key === vehicleType);

    return foundVehicle ? foundVehicle.value : labels.na;
  }

  static getOutFitName(outfit, vehicleTypes, towTypes) {
    const vehicle = DataLayerManager.getVehicleName(outfit.vehicleType, vehicleTypes);
    let tow = DataLayerManager.getVehicleName(outfit.towType, towTypes);
    if (tow.toLowerCase() !== labels.na) {
      tow = ` + ${tow}`;
    } else {
      tow = '';
    }
    return `${vehicle}${tow}`;
  }

  static getFormattedPrice(price) {
    const priceNumber = parseFloat(price);
    if (!Number.isNaN(priceNumber)) {
      return priceNumber.toFixed(2);
    }

    return undefined;
  }

  static getFormattedDate(date) {
    const dateObject = new Date(date);
    if (isValid(dateObject)) {
      return dateObject.toISOString();
    }

    return undefined;
  }

  static getCategoryForSites(datum) {
    const ukTypes = [
      campsiteTypeIds.CERTIFICATED_LOCATIONS,
      campsiteTypeIds.UK_AFFILIATED_SITES,
      campsiteTypeIds.UK_CLUB_SITES,
    ];

    if (ukTypes.includes(datum.type)) {
      return category.UK;
    }

    return category.OVERSEAS;
  }

  static getCrossingSubCategory(datum, ports, zoneTypes) {
    return `${DataLayerManager.getDestinationPortZone(datum, ports, zoneTypes)} Crossing`;
  }

  static getDestinationPortZone(crossingDatum, ports, zoneTypes) {
    const outBoundArrivalPortZone = DataLayerManager.findPortZoneViaPortCode(
      crossingDatum.outboundItinerary.arrivalPort,
      ports,
    );

    return zoneTypes[outBoundArrivalPortZone].value;
  }

  static findPortViaPortCode(portCode, ports) {
    const foundPort = ports.find(port => (port.portCodes.indexOf(portCode) > -1));

    return foundPort;
  }

  static findPortZoneViaPortCode(portCode, ports) {
    const foundPort = DataLayerManager.findPortViaPortCode(portCode, ports);
    if (foundPort) {
      return foundPort.zone;
    }

    return -1;
  }

  static daysBetweenDates(startDate, endDate) {
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  setApolloClient(apolloClient) {
    apolloClient.then((client) => { this.apolloClient = client; });
  }

  // eslint-disable-next-line
  orderDataProperties(unordered) {
    const ordered = {};

    Object.keys(unordered).sort().forEach((key) => {
      ordered[key] = unordered[key];
    });

    return ordered;
  }

  checkForUniquePush = (type, data = [], expiry = 10000, unique = true) => {
    let stringified = '';
    if (data.constructor === Array) {
      if (data.every(datum => !!datum.id)) {
        stringified = data.reduce((str, i) => str + i.id, '');
      } else {
        throw new Error(`checkForUniquePush(${type}, ${data}) data array has missing id's`);
      }

      if (data.length === 0) {
        return false;
      }
    } else if (data.constructor === Object) {
      if (data.id) {
        stringified = String(data.id);
      } else {
        throw new Error(`checkForUniquePush(${type}, ${data}) data object has missing id`);
      }
    } else if (data.constructor === String) {
      stringified = data;
    }

    if (stringified !== '') {
      const history = this.pushHistory[type];

      const now = new Date().getTime();
      if (
        !history ||
        ((!unique || history.stringified !== stringified) && history.timestamp + expiry < now)
      ) {
        this.pushHistory[type] = {
          timestamp: now,
          stringified,
        };
        return true;
      }
    }

    return false;
  };

  /**
   *
   * @returns {
   *  brand
   *  category
   *  currency
   *  dimension63
   *  dimension69
   *  dimension70
   *  id
   *  name
   *  price
   *  url
   *  variant
   * }
   */
  processCrossingsProductData = (datum, index) => {
    const {
      configuration: {
        fareTypes,
        ports,
        suppliers,
        towTypes,
        vehicleTypes,
      },
    } = this.apolloClient.readQuery({ query: GET_CONFIGURATION });

    const {
      configurationCrossings: {
        portZoneTypes: zoneTypes,
      },
    } = this.apolloClient.readQuery({ query: GET_CROSSINGS_CONFIG });

    let fareType = labels.na;
    const foundType = fareTypes.find(t => t.key === datum.fareType);
    if (foundType) {
      fareType = foundType.value;
    }

    const name = `${DataLayerManager.getCrossingPrettyName(
      datum.outboundItinerary.departurePort,
      datum.outboundItinerary.arrivalPort,
      ports,
    )} (${fareType})`;

    const data = {
      brand: DataLayerManager.getSupplierPortName(datum.supplierCode, suppliers),
      category: category.CROSSINGS,
      dimension69: datum.outboundItinerary.timeTable?.fromDate,
      id: datum.relatedFareTypeId,
      list: list.CROSSINGS,
      name,
      price: DataLayerManager.getFormattedPrice(datum.charges.totalAmount),
      dimension82: DataLayerManager.getCrossingSubCategory(datum, ports, zoneTypes),
      quantity: 1,
    };

    if (datum.outfit && datum.outfit.vehicleType) {
      data.dimension63 = DataLayerManager.getOutFitName(datum.outfit, vehicleTypes, towTypes);
    }

    if (datum.inboundItinerary) {
      data.dimension70 = datum.inboundItinerary.timeTable?.fromDate;
    }

    if (datum.quantity) {
      data.quantity = datum.quantity;
    }

    return this.orderDataProperties(data);
  };

  /**
   *
   * @returns {
   *  brand
   *  category (Uk or Overseas)
   *  currency
   *  dimension61 (Pitch Type)
   *  dimension62 (Party size)
   *  dimension63 (Outfit)
   *  dimension64 (Town or City)
   *  dimension65 (site rating)
   *  dimension66 (Country)
   *  dimension67 (Start Date)
   *  dimension68 (End Date)
   *  dimension82 (product subcategory eg: Campsite, Voucher or Extra)
   *  dimension92 (The promotion applied to the campsite. eg: Spring Deal - 10% Off )
   *  id
   *  list
   *  name
   *  price
   *  position
   *  quantity
   *  url
   *  variant (Number of nights stay)
   * }
   */
  processSitesProductData = (datum, index) => {
    const {
      configuration: {
        vehicleTypes,
        towTypes,
      },
    } = this.apolloClient.readQuery({
      query: GET_CONFIGURATION,
    });

    const data = {
      brand: brand.CAMC,
      category: DataLayerManager.getCategoryForSites(datum),
      currency: labels.currencyCode,
      id: datum.id,
      name: datum.name,
      price: DataLayerManager.getFormattedPrice(datum.memberPrice || datum.price),
    };

    if (datum.subCategory !== subCategory.VOUCHER) {
      data.url = `${routes.sites}?types=${datum.type}&activePin=${datum.id}&campsiteId=${datum.id}`;
    }

    // ProductImpression, ProductClick
    if (index != null || datum.position != null) {
      data.list = list.SITES;
      data.position = (datum.position) ? datum.position + 1 : index + 1;
    }

    // CheckoutProduct, RemoveProduct, AddProduct
    if (datum.quantity) {
      data.quantity = datum.quantity;
    }

    if (datum.start && datum.end) {
      data.variant = DataLayerManager.daysBetweenDates(new Date(datum.start), new Date(datum.end));
    }

    if (datum.pitchName) {
      data.dimension61 = `${datum.pitchName} (${datum.pitchTypeId})`;
    }

    if (datum.partyMembers && datum.partyMembers.length) {
      data.dimension62 = datum.partyMembers.length;
    }

    if (datum.outfit && datum.outfit.vehicleType) {
      data.dimension63 = DataLayerManager.getOutFitName(datum.outfit, vehicleTypes, towTypes);
    }

    // @NOTE Town City not available
    // data.dimension64 = 'Town/City not available';

    if (datum.rating) {
      data.dimension65 = datum.rating;
    }

    if (datum.address && datum.address.country) {
      data.dimension66 = datum.address.country;
    }

    if (datum.campsiteAddress && datum.campsiteAddress.country) {
      data.dimension66 = datum.campsiteAddress.country;
    }

    if (datum.start) {
      data.dimension67 = DataLayerManager.getFormattedDate(datum.start);
    }

    if (datum.end) {
      data.dimension68 = DataLayerManager.getFormattedDate(datum.end);
    }

    data.dimension82 = datum.subCategory ||
      (datum.crossing ? subCategory.CROSSINGS : subCategory.CAMPSITE);

    return this.orderDataProperties(data);
  };

  processToursProductData = (datum, index) => {
    const data = {
      brand: brand.CAMC,
      category: datum.subCategory === subCategory.EUROPEAN_TOUR ? category.OVERSEAS : category.UK,
      currency: labels.currencyCode,
      id: datum.id,
      name: datum.name,
    };
    const position = datum.position || index;

    if (datum.subCategory === subCategory.EUROPEAN_TOUR) {
      let url = `${routes.sites}?isOverseas=true&isTours=true`;
      if (datum.duration) {
        url += `&duration=${datum.duration}`;
      }
      if (datum.departureMonth) {
        url += `&departureMonth=${datum.departureMonth}`;
      }
      if (datum.location) {
        url += `&location=${datum.location}`;
      }
      data.url = url;
    }
    if (datum.subCategory === subCategory.UK_ITINERARY) {
      let url = `${routes.sites}`;
      if (datum.ukItinerary) {
        url += `?showResults=true&ukItinerary=${datum.ukItinerary}`;
      }
      data.url = url;
    }

    if (position != null) {
      data.list = datum.subCategory === subCategory.EUROPEAN_TOUR ?
        list.EUROPEAN_TOURS : list.UK_ITINERARIES;
      data.position = (datum.position) ? datum.position + 1 : index + 1;
    }

    if (datum.price) {
      data.price = DataLayerManager.getFormattedPrice(datum.price);
    }

    data.dimension82 = datum.subCategory;

    return this.orderDataProperties(data);
  };

  processCheckoutData(quote) {
    const products = [];

    quote.campsiteBookings.forEach(campsite => campsite && campsite.pitches.forEach((pitch) => {
      products.push(this.processSitesProductData({
        name: campsite.campsite.name,
        id: campsite.campsite.id,
        type: campsite.campsite.type,
        price: pitch.totalPrice,
        quantity: 1,
        pitchName: pitch.type,
        pitchTypeId: pitch.code,
        partyMembers: quote.partyMembers,
        outfit: quote.outfit,
        address: campsite.campsite.address,
        start: pitch.bookingDates.fromDate,
        end: pitch.bookingDates.toDate,
      }));
    }));

    quote.crossingBookings.forEach((crossing) => {
      const inbound = {};
      if (crossing.inboundItinerary) {
        inbound.inboundItinerary = {
          departurePort: crossing.inboundItinerary.departurePort,
          arrivalPort: crossing.inboundItinerary.arrivalPort,
          crossingDateTime: crossing.inboundItinerary.crossingDateTime,
        };
      }

      products.push(this.processCrossingsProductData({
        relatedFareTypeId: crossing.relatedFareTypeId,
        category: category.CROSSINGS,
        supplierCode: crossing.supplierCode,
        fareType: crossing.fareType,
        charges: {
          totalAmount: crossing.totalPrice,
        },
        outboundItinerary: {
          departurePort: crossing.outboundItinerary.departurePort,
          arrivalPort: crossing.outboundItinerary.arrivalPort,
          crossingDateTime: crossing.outboundItinerary.crossingDateTime,
        },
        ...inbound,
      }));
    });

    quote.extras.forEach((extra) => {
      products.push(this.processSitesProductData({
        name: extra.name,
        id: bespokeProductIds.OVERSEAS_SITE_NIGHT_VOUCHERS,
        memberPrice: extra.totalPrice,
        category: category.SITES,
        subCategory: subCategory.EXTRA,
        type: 'Overseas',
        quantity: extra.quantity,
      }));
    });

    if (isMembershipInBasket(quote)) {
      const {
        configuration: {
          membershipTypes: {
            additionalFamilyMemberCost,
            directDebit,
            creditCard,
          },
        },
      } = this.apolloClient.readQuery({ query: GET_CONFIGURATION });
      let membershipType = getENumWithValue(quoteMembershipTypes.MembershipByDD);
      let membershipCosts = directDebit;
      let membershipCost = membershipCosts.costSingle;
      let membershipMode = MEMBERSHIP_MODE.LEAD;

      if (quote.membership) {
        if (quote.membership.resultType) {
          membershipType = getENumWithValue(quoteMembershipTypes.MembershipByCC);
          membershipCosts = creditCard;
        }
        const familyMembersLength = quote.membership.familyMembers?.length ?? 0;
        if (quote.membership.joint) {
          membershipCost = membershipCosts.costJoint;
          membershipMode = MEMBERSHIP_MODE.JOINT;
        } else if (familyMembersLength) {
          membershipMode = MEMBERSHIP_MODE.FAMILY;
        }
        if (familyMembersLength) {
          membershipCost += (familyMembersLength * additionalFamilyMemberCost);
        }
      }
      products.push(this.processMembershipProductData({
        price: membershipCost,
        quantity: 1,
        membershipType,
        dimension84: membershipMode,
        checkout: true,
      }));
    }

    return products;
  }

  processMembershipProductData = (datum) => {
    let url = routes.sites;
    if (datum.crossing) {
      url = routes.crossings;
    }
    if (datum.checkout) {
      url = routes.checkout;
    }
    if (datum.id && datum.siteCode) {
      url += `?activePin=${datum.id}&campsiteId=${datum.id}&siteCode=${datum.siteCode}`;
    }
    const data = {
      brand: brand.CAMC,
      category: category.MEMBERSHIP,
      currency: labels.currencyCode,
      id: MEMBERSHIP_ID,
      name: MEMBERSHIP_NAME,
      price: DataLayerManager.getFormattedPrice(datum.price),
      url,
    };

    if (datum.quantity) {
      // Add To Cart, Remove From Cart Checkout
      data.quantity = datum.quantity;
      data.dimension84 = MEMBERSHIP_MODE.LEAD;
    } else {
      // Product View
      data.dimension87 = MEMBERSHIP_SOURCE;
    }

    if (datum.dimension84) {
      data.dimension84 = datum.dimension84;
    }
    if (datum.membershipType) {
      data.variant = MEMBERSHIP_TYPE[datum.membershipType];
    }

    return this.orderDataProperties(data);
  }

  processProductData = (type, data, index) => {
    let productData;

    if (data) {
      switch (type) {
        case category.MEMBERSHIP:
          productData = this.processMembershipProductData(data);
          break;
        case subCategory.EUROPEAN_TOUR:
          productData = this.processToursProductData(data, index);
          break;
        case subCategory.UK_ITINERARY:
          productData = this.processToursProductData(data, index);
          break;
        case category.CROSSINGS:
          productData = this.processCrossingsProductData(data, index);
          break;
        case category.SITES:
          productData = this.processSitesProductData(data, index);
          break;
        case subCategory.CROSSINGS:
          productData = this.processCrossingsProductData(data, index);
          break;
        case subCategory.CAMPSITE:
          productData = this.processSitesProductData(data, index);
          break;
        default:
      }
    }

    return productData;
  };

  pushImpressions = (categoryType, array = []) => {
    if (!this.checkForUniquePush(event.IMPRESSIONS_PUSHED, array, 5000, false)) return;

    window.dataLayer.push({
      currencyCode: labels.currencyCode,
      ecommerce: {
        impressions: array?.map((item, index) => this.processProductData(
          categoryType, item, index,
        )),
      },
      event: event.IMPRESSIONS_PUSHED,
    });
  };

  pushAddToCart = (subCategoryType, data, remove) => {
    const productData = this.processProductData(subCategoryType, data);
    const cartEvent = remove ? event.REMOVE_FROM_CART : event.ADD_TO_CART;
    if (!this.checkForUniquePush(cartEvent, JSON.stringify(productData), 5000, false)) return;

    window.dataLayer.push({
      ecommerce: {
        currencyCode: labels.currencyCode,
        add: {
          products: [productData],
        },
      },
      event: cartEvent,
    });
  };

  pushProductClick = (categoryType, data, index) => {
    const productData = this.processProductData(categoryType, data);

    if (!this.checkForUniquePush(event.PRODUCT_CLICK, JSON.stringify(productData))) return;

    let listType = categoryType === category.CROSSINGS ? list.CROSSINGS : list.SITES;
    if (categoryType === subCategory.EUROPEAN_TOUR) {
      listType = list.EUROPEAN_TOURS;
    }
    if (categoryType === subCategory.UK_ITINERARY) {
      listType = list.UK_ITINERARIES;
    }
    window.dataLayer.push({
      ecommerce: {
        click: {
          actionField: {
            list: listType,
          },
          products: [productData],
        },
      },
      event: event.PRODUCT_CLICK,
    });
  };

  pushProductDetails = (categoryType, data) => {
    const productData = this.processProductData(categoryType, data);
    if (!this.checkForUniquePush(event.PRODUCT_DETAIL, JSON.stringify(productData), 5000)) return;

    window.dataLayer.push({
      ecommerce: {
        currencyCode: labels.currencyCode,
        detail: {
          products: [productData],
        },
      },
      event: event.PRODUCT_DETAIL,
    });
  }

  pushPromoClick = (creative, dynamic, promoKey, name, position) => {
    window.dataLayer.push({
      ecommerce: {
        promoClick: {
          promotions: [{
            creative, // Name of the creative used to showcase the promotion
            // (e.g.’ banner1’).
            dynamic, // True if the banner was injected onto the page for personalisation
            // purposes (e.g. it isn’t there by default, or the default one was replaced).
            id: getIdFromString(promoKey), // predictable unique id
            name, // Name of the promotion being shown (e.g. ‘backToSchool’).
            position, // The name of the slot on the page where the promotion appeared
            // (e.g. ‘heroBanner’).
          }],
        },
      },
      event: event.PROMO_CLICK,
    });
  }

  pushPromoView = (creative, dynamic, promoKey, name, position) => {
    window.dataLayer.push({
      ecommerce: {
        promoView: {
          promotions: [{
            creative, // Name of the creative used to showcase the promotion
            // (e.g.’ banner1’).
            dynamic, // True if the banner was injected onto the page for personalisation
            // purposes (e.g. it isn’t there by default, or the default one was replaced).
            id: getIdFromString(promoKey), // predictable unique id
            name, // Name of the promotion being shown (e.g. ‘backToSchool’).
            position, // The name of the slot on the page where the promotion appeared
            // (e.g. ‘heroBanner’).
          }],
        },
      },
      event: event.PROMO_VIEW,
    });
  }

  pushCheckout = (quoteData, step, option) => {
    const actionField = {};
    if (step) actionField.step = step;
    if (option) actionField.option = option;

    window.dataLayer.push({
      ecommerce: {
        currencyCode: labels.currencyCode,
        checkout: {
          actionField,
          products: this.processCheckoutData(quoteData),
        },
      },
      event: event.CHECKOUT,
    });
  }

  // TODO add properties
  pushPurchase = (quoteData, id, revenue) => {
    window.dataLayer.push({
      ecommerce: {
        currencyCode: labels.currencyCode,
        purchase: {
          actionField: {
            // affiliation: 'Online Store',
            coupon: undefined,
            couponDiscount: undefined,
            currency: labels.currencyCode,
            id,
            paymentMethod: undefined,
            returning: undefined,
            revenue,
            tax: undefined,
            timestamp: new Date().getTime(),
          },
          products: this.processCheckoutData(quoteData),
        },
      },
      event: event.PURCHASE,
    });
  }

  pushRevealCampsiteAddress = () => {
    window.dataLayer.push({
      event: 'RevealCampsiteAddress',
    });
  };

  /**
   * We are manually pushing virtual page views, as apossed to listening to the Router change
   * as the virtual pages CAMC want tracked are not separate routes. So we are queing these
   * manually.
   *
   * @param {Object} virtualPageViewObject object containing pathname and title
   */
  /* eslint-disable */ // allow method not to have this and allow underscores.
  pushVirtualPageViewEvent(virtualPageViewObject) {

    if (virtualPageViewObject && virtualPageViewObject?.pathname !== window.__IBECurrentPath) {
      // we ignore first attempt as this will not be a virtual page view but a bonefied page view.
      if (window.__IBECurrentPath !== undefined) {
        window.dataLayer.push({
          event: 'VirtualPageview',
          virtualPageURL: virtualPageViewObject.pathname,
          virtualPageTitle: virtualPageViewObject.title,
        });
      }
      window.__IBECurrentPath = virtualPageViewObject.pathname;
    }
  }
  /**
   * Push an event to the dataLayer when a user adds, edits or removes
   * part stay guests
   * @param {object}  payload     The current quote
   * @param {object}  guestParty  The duration the part-stay guests have been added for
   * @param {string}  eventType   The type of event which has occurred
   * @returns {void}
  */
  pushPartStayGuests(payload, guestParty, eventType) {
    if (!window.dataLayer) return;
    const { partyMembers, start: stayStart, end: stayEnd } = payload;
    const { guests = [], start, end } = guestParty ?? {};
    window.dataLayer.push({
      event: event.PART_STAY_GUESTS,
      eventType,
      mainParty: formatGuestsForDataLayer(partyMembers),
      mainPartyDuration: {
        start: stayStart,
        end: stayEnd,
      },
      guestParty: formatGuestsForDataLayer(guests),
      guestPartyDuration: {
        start: start ?? null,
        end: end ?? null,
      },
    });
  }
}

export const dataLayerManager = DataLayerManager.instance;

export default null;
