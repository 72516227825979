import { ApolloClient } from 'apollo-client';
import { RestLink } from 'apollo-link-rest';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { addMinutes, isBefore } from 'date-fns';
import { merge } from 'lodash';
import getConfig from 'next/config';

import fetchHeaders from 'fetch-headers/headers-es5.min';

import CachePersistor from './CachePersistence/CachePersistor';

// Resolvers
import { defaults as allDefaults, resolvers as allResolvers, persistentDefaults } from './resolvers';
import * as resolvers from '../resolvers';

import getReadableCookieValue from './getReadableCookieValue';

import typeDefs from './typeDefs';
import { GOOGLE_API_ORIGIN } from '../config/apis';
import { dataLayerManager } from './dataLayer';
import { navigateTo } from './helpers/navigation';
import logToCasClientSide, { AuditType, safelyToString } from './logToCasClientSide';
import {
  StorageType,
  getGenericStorageItem,
  removeGenericStorageItem,
  setGenericStorageItem,
} from './storage';

// Import polyfills
require('es6-promise').polyfill();
require('isomorphic-fetch');

// Polyfill fetch() on the server (used by apollo-client)
if (!global.fetch) {
  global.fetch = fetch;
}

// Polyfill Headers on the server (used by apollo-link-rest)
if (!global.Headers) {
  global.Headers = fetchHeaders;
}

const { publicRuntimeConfig } = getConfig();
const cacheBusterRefreshMinutes = publicRuntimeConfig.CacheBusterRefreshMinutes || 0;

const {
  CACHE_EXPIRY_KEY,
  SCHEMA_KEY,
  SCHEMA_VERSION_KEY,
  SCHEMA_COMPRESSION_ENABLED,
} = require('../config/data');

let apolloClient = null;

/**
 * Create the Apollo client
 * @param {Object} initialState
 */
const create = async (initialState) => {
  // Setup cache and restore initialstate from serve
  const cache = new InMemoryCache({
    dataIdFromObject: (object) => {
      // eslint-disable-next-line no-underscore-dangle
      switch (object?.__typename) {
        case 'Bookings':
          return `Bookings:${object.bcode}`;
        case 'DictionaryItem':
          return `DictionaryItem:${object.key}`; // use `key` as the primary key
        case 'Feature':
          return `Feature:${object.id}-${object.value}`;
        case 'SpecialRequest':
          return `SpecialRequest:${object.id}-${object.name.split(' ').join('_')}`;
        case 'Quote':
          return `Quote:${object?.bookingReference}`;
        case 'Telephone':
          return `Telephone:${object.countryPrefix}-${object.number}`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
    // Request data that already exists in the client store under a different key
    cacheRedirects: {
      Query: {
        dictionaryFind: (_, args, { getCacheKey }) => args.keys.map(({ key }) => getCacheKey({ __typename: 'DictionaryItem', key })),
        dictionaryItem: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'DictionaryItem', key: args.key }),
        groupedCrossing: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'GroupedCrossing', key: args.id }),
        quote: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Quote', key: null }),
      },
    },
  }).restore(initialState || {});

  // Setup error handler
  const errorLink = onError((errorEvent) => {
    const { networkError, operation, forward } = errorEvent;

    // get as much data as we can from the incoming error.
    let errorEventString;
    try {
      errorEventString = safelyToString(errorEvent);
    } catch (e) {
      errorEventString = '';
    }

    // Attempting to get a useful action name
    const action = networkError?.response?.url || networkError?.toString() || 'unknown error';
    logToCasClientSide(AuditType.Error, 'initApollo.errorLink()', action, errorEventString);

    // Check if we're using the SafeQuery component which handles error reporting to Sentry
    if (operation.getContext().handled) return null;

    // const loggedIn = isLoggedIn();
    if (!networkError) return null;
    // ignore this error as SSR is not operational
    if (!networkError?.message === 'TypeError: Only absolute URLs are supported') {
      console.error(`[Network error]: ${errorEventString}`);
    }

    // Network error status is 401, explicit handling and redirection to login again.
    if (
      networkError?.statusCode === 401
      && typeof window !== 'undefined'
      && window.location.pathname.includes('confirmation')
      && networkError?.response?.url?.includes('/api/user')
    ) {
      // eslint-disable-next-line no-alert
      alert('There has been a problem with authorising your login, you will be redirected back to the login page');
      // upon 401 redirect to login page
      const { LoginUrl } = getConfig().publicRuntimeConfig.ENVIRONMENT;
      const query = encodeURIComponent(window.location.search);
      const ReturnUrl = `${window.location.href.split('?')[0]}${query}`;
      navigateTo(`${LoginUrl}?UseReturnURL=true&ReturnUrl=${ReturnUrl}`);
      return forward(operation);
    }

    return null;
  });

  // REST API's don't have __Typename
  // For this reason we need to patch nested responses to allow us to store them as GraphQL
  const patchIfExists = (
    data,
    key,
    __typename,
    patcher = RestLink.FunctionalTypePatcher,
  ) => {
    const value = data[key];
    if (value == null) return {};
    return { [key]: patcher(value, __typename, patcher) };
  };

  const typePatcher = {
    CurrentUserBookings(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'bookings', 'Bookings', patchDeeper),
      };
    },
    CurrentUserBooking(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'bookingDetails', 'BookingDetails', patchDeeper),
      };
    },
    BookingDetails(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'partyMembers', 'PartyMember', patchDeeper),
        ...patchIfExists(obj, 'outfit', 'Outfit', patchDeeper),
        ...patchIfExists(obj, 'phoneNumber', 'PhoneNumber', patchDeeper),
        ...patchIfExists(
          obj,
          'campsiteBookings',
          'CampsiteBookingDetails',
          patchDeeper,
        ),
        ...patchIfExists(
          obj,
          'crossingBookings',
          'CrossingBookingDetails',
          patchDeeper,
        ),
        ...patchIfExists(obj, 'extras', 'Extra', patchDeeper),
      };
    },
    Address(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'address', 'Address', patchDeeper),
      };
    },
    Availability(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'campsiteTypes', 'KeyValue', patchDeeper),
        ...patchIfExists(obj, 'errorCodeTypes', 'KeyValue', patchDeeper),
        ...patchIfExists(obj, 'features', 'Feature', patchDeeper),
        ...patchIfExists(obj, 'partyMemberTypes', 'KeyValue', patchDeeper),
        ...patchIfExists(obj, 'pitchTypes', 'PitchTypes', patchDeeper),
        ...patchIfExists(obj, 'vehicleTypes', 'KeyValue', patchDeeper),
        ...patchIfExists(obj, 'telephoneTypes', 'KeyValue', patchDeeper),
        ...patchIfExists(obj, 'towTypes', 'KeyValue', patchDeeper),
      };
    },
    Campsite(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      const patchedObj = patchIfExists(obj, 'events', 'Event', patchDeeper);
      return {
        ...obj,
        ...patchIfExists(obj, 'features', 'Feature', patchDeeper),
        ...patchIfExists(obj, 'openDates', 'OpenDate', patchDeeper),
        ...patchIfExists(obj, 'pitches', 'Pitches', patchDeeper),
        ...patchIfExists(obj, 'address', 'Address', patchDeeper),
        ...patchIfExists(obj, 'location', 'Location', patchDeeper),
        events: patchedObj?.events?.map((event) => ({
          ...event,
          ...patchIfExists(event, 'openDates', 'OpenDate', patchDeeper),
          ...patchIfExists(event, 'features', 'Feature', patchDeeper),
          __typename: 'Event',
        })) ?? [],
      };
    },
    UserOutfits(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'outfits', 'Outfit', patchDeeper),
      };
    },
    Configuration(obj, outerType, patchDeeper) {
      if (obj === null) return null;
      return {
        ...obj,
        ...patchIfExists(obj, 'amendAndCancelExtraRestrictions', 'AmendAndCancelExtraRestrictions', patchDeeper),
        ...patchIfExists(obj, 'configurableValues', 'ConfigurableValues', patchDeeper),
        ...patchIfExists(obj, 'extrasInfo', 'ExtrasInfo', patchDeeper),
        ...patchIfExists(obj, 'itxPackageRules', 'ItxPackageRules', patchDeeper),
        ...patchIfExists(obj, 'membershipContent', 'MembershipContent', patchDeeper),
        ...patchIfExists(obj, 'membershipTypes', 'MembershipTypes', patchDeeper),
        ...patchIfExists(obj, 'siteNightVoucherInfo', 'SiteNightVoucherInfo', patchDeeper),
        ...patchIfExists(obj, 'suppliers', 'Suppliers', patchDeeper),
        ...patchIfExists(obj, 'towTypes', 'OutfitType', patchDeeper),
        ...patchIfExists(obj, 'vehicleTypes', 'OutfitType', patchDeeper),
        ...patchIfExists(obj, 'partyMemberTypes', 'PartyMemberTypes', patchDeeper),
        ...patchIfExists(obj, 'ports', 'Ports', patchDeeper),
        ...patchIfExists(obj, 'products', 'Products', patchDeeper),
        ...patchIfExists(obj, 'quoteChangeTypes', 'QuoteChangeTypes', patchDeeper),
        ...patchIfExists(obj, 'quoteComponentTypes', 'QuoteComponentTypes', patchDeeper),
        ...patchIfExists(obj, 'quoteMembershipTypes', 'QuoteMembershipTypes', patchDeeper),
      };
    },
    ConfigurableValues(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
      };
    },
    SitesConfiguration(obj, outerType, patchDeeper) {
      if (obj === null) return null;
      return {
        ...obj,
        ...patchIfExists(obj, 'campsiteTypes', 'CampsiteTypes', patchDeeper),
        ...patchIfExists(obj, 'sortOrderTypes', 'SortOrderTypes', patchDeeper),
        ...patchIfExists(obj, 'featureTypes', 'FeatureTypes', patchDeeper),
        ...patchIfExists(obj, 'features', 'Features', patchDeeper),
        ...patchIfExists(obj, 'campaignCodes', 'CampaignCodes', patchDeeper),
        ...patchIfExists(obj, 'onSiteFilterableFeatures', 'OnSiteFilterableFeatures', patchDeeper),
        ...patchIfExists(obj, 'nearbyFilterableFeatures', 'NearbyFilterableFeatures', patchDeeper),
        ...patchIfExists(obj, 'pointsOfInterestFilterableFeatures', 'PointsOfInterestFilterableFeatures', patchDeeper),
        ...patchIfExists(obj, 'disabledFilterableFeatures', 'DisabledFilterableFeatures', patchDeeper),
        ...patchIfExists(obj, 'ukSitesTabDefaultSearchSortOrderTypes', 'UkSitesTabDefaultSearchSortOrderTypes', patchDeeper),
        ...patchIfExists(obj, 'overseasSitesTabDefaultSearchSortOrderTypes', 'OverseasSitesTabDefaultSearchSortOrderTypes', patchDeeper),
        ...patchIfExists(obj, 'escortedToursDurationTypes', 'EscortedToursDurationTypes', patchDeeper),
        ...patchIfExists(obj, 'suggestedUkItineraryCategories', 'SuggestedUkItineraryCategory', patchDeeper),
        ...patchIfExists(obj, 'excludeGeoLocationTypes', 'ExcludeGeoLocationTypes', patchDeeper),
        ...patchIfExists(obj, 'mapConfiguration', 'MapConfiguration', patchDeeper),
      };
    },
    CrossingsConfiguration(obj, outerType, patchDeeper) {
      if (obj === null) return null;
      return {
        ...obj,
        ...patchIfExists(obj, 'portZoneTypes', 'PortZoneTypes', patchDeeper),
        ...patchIfExists(obj, 'routes', 'Routes', patchDeeper),
      };
    },
    Features(obj, outerType, patchDeeper) {
      if (obj === null) return null;
      return {
        ...obj,
        ...patchIfExists(obj, 'allFeatures', 'Feature', patchDeeper),
        ...patchIfExists(obj, 'idealFor', 'Feature', patchDeeper),
        ...patchIfExists(obj, 'nearby', 'Feature', patchDeeper),
        ...patchIfExists(obj, 'onSiteFacilities', 'Feature', patchDeeper),
        ...patchIfExists(obj, 'thingsToDo', 'Feature', patchDeeper),
      };
    },
    Routes(obj, outerType, patchDeeper) {
      if (obj === null) return null;
      return {
        ...obj,
        ...patchIfExists(obj, 'arrivalPortLocation', 'ArrivalPortLocation', patchDeeper),
        ...patchIfExists(obj, 'departurePortLocation', 'DeparturePortLocation', patchDeeper),
        ...patchIfExists(obj, 'location', 'Location', patchDeeper),
        ...patchIfExists(obj, 'routeLine', 'RouteLine', patchDeeper),
      };
    },
    campaignCodes(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'searchDate', 'SearchDate', patchDeeper),
      };
    },
    CampsiteBooking(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'campsite', 'Campsite', patchDeeper),
        ...patchIfExists(obj, 'pitches', 'Pitch', patchDeeper),
      };
    },
    CampsiteBookingDetails(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'campsite', 'Campsite', patchDeeper),
        ...patchIfExists(obj, 'pitches', 'BookingPitch', patchDeeper),
      };
    },
    CampsitePin(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      const patchedPin = patchIfExists(obj, 'events', 'Event', patchDeeper);
      return {
        __typename: 'CampsitePin',
        ...obj,
        events: patchedPin?.events?.map((event) => ({
          ...event,
          ...patchIfExists(event, 'openDates', 'OpenDate', patchDeeper),
          ...patchIfExists(event, 'features', 'Feature', patchDeeper),
        })) ?? [],
      };
    },
    CampsiteListing(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      const patchedCampsite = patchIfExists(obj, 'data', 'Campsite', patchDeeper);
      return {
        ...obj,
        data: patchedCampsite?.data?.map((campsite) => ({
          ...campsite,
          ...patchIfExists(campsite, 'topFeatures', 'Feature', patchDeeper),
        })),
      };
    },
    CampsitePriceById(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'data', 'CampsitePrice', patchDeeper),
      };
    },
    Footer(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'footerConfiguration', 'FooterConfiguration', patchDeeper),
        ...patchIfExists(obj, 'footerLinks', 'FooterLinks', patchDeeper),
        ...patchIfExists(obj, 'sections', 'FooterSections', patchDeeper),
      };
    },
    Header(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'headerConfiguration', 'HeaderConfiguration', patchDeeper),
        ...patchIfExists(obj, 'headerLinks', 'HeaderLinks', patchDeeper),
        ...patchIfExists(obj, 'headerMenus', 'HeaderMenus', patchDeeper),
      };
    },
    HeaderMenus(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'columns', 'HeaderMenuColumn', patchDeeper),
      };
    },
    HeaderMenuColumn(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'linkItems', 'headerMenuColumnItemLink', patchDeeper),
      };
    },
    LayoutConfig(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'footer', 'Footer', patchDeeper),
        ...patchIfExists(obj, 'header', 'Header', patchDeeper),
      };
    },
    MemberDetails(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'address', 'Address', patchDeeper),
        ...patchIfExists(obj, 'addressLines', 'AddressLines', patchDeeper),
        ...patchIfExists(obj, 'telephones', 'Telephone', patchDeeper),
      };
    },
    PartyMember(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'personName', 'PersonName', patchDeeper),
      };
    },
    Pitch(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'supplements', 'Supplement', patchDeeper),
        ...patchIfExists(obj, 'bookingDates', 'BookingDates', patchDeeper),
      };
    },
    BookingPitch(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'supplements', 'Supplement', patchDeeper),
        ...patchIfExists(obj, 'bookingDates', 'BookingDates', patchDeeper),
        ...patchIfExists(obj, 'nightlyPrices', 'NightlyPrice', patchDeeper),
      };
    },
    Prices(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      const newObj = {
        ...obj,
        data: obj.data?.filter(priceData => !!priceData),
      };
      return {
        ...newObj,
        ...patchIfExists(newObj, 'data', 'CampsitePrice', patchDeeper),
      };
    },
    Quote(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'partyMembers', 'PartyMember', patchDeeper),
        ...patchIfExists(obj, 'outfit', 'Outfit', patchDeeper),
        ...patchIfExists(obj, 'phoneNumber', 'PhoneNumber', patchDeeper),
        ...patchIfExists(
          obj,
          'campsiteBookings',
          'CampsiteBooking',
          patchDeeper,
        ),
        ...patchIfExists(
          obj,
          'crossingBookings',
          'CrossingBooking',
          patchDeeper,
        ),
        ...patchIfExists(obj, 'extras', 'Extra', patchDeeper),
      };
    },
    QuoteUpdate(obj, outerType, patchDeeper) {
      if (obj === null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'quote', 'Quote', patchDeeper),
      };
    },
    CrossingBooking(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'inboundItinerary', 'Itinerary', patchDeeper),
        ...patchIfExists(obj, 'outboundItinerary', 'Itinerary', patchDeeper),
      };
    },
    Itinerary(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'accommodation', 'Accommodation', patchDeeper),
        ...patchIfExists(obj, 'supplements', 'Supplement', patchDeeper),
        ...patchIfExists(obj, 'timeTable', 'TimeTable', patchDeeper),
      };
    },
    OutboundItinerary(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'timeTable', 'TimeTable', patchDeeper),
      };
    },
    Reviews(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'data', 'CampsiteReviews', patchDeeper),
      };
    },
    SearchCrossings(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'crossings', 'Crossings', patchDeeper),
      };
    },
    Crossings(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'inboundItinerary', 'Itinerary', patchDeeper),
        ...patchIfExists(obj, 'outboundItinerary', 'Itinerary', patchDeeper),
        ...patchIfExists(obj, 'charges', 'Charges', patchDeeper),
      };
    },
    UkItineraries(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'suggestedUkItineraries', 'UkItineraries', patchDeeper),
      };
    },
    Tours(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
        ...patchIfExists(obj, 'tours', 'Tours', patchDeeper),
      };
    },
    Telephone(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
      };
    },
    TermsAndConditions(obj, outerType, patchDeeper) {
      if (obj == null) return obj;
      return {
        ...obj,
      };
    },
  };

  const headers = new Headers();
  headers.append('Allow-Access-Control-Origin', '*');

  // Create a RestLink for the REST API
  // If you are using multiple link types, restLink should go before httpLink,
  // as httpLink will swallow any calls that should be routed through rest!
  const restLink = new RestLink({
    uri: '/api/', // Express proxies /api to actual API URL with Auth header
    endpoints: {
      googleApis: GOOGLE_API_ORIGIN,
    },
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
    customFetch: fetch,
    typePatcher,
    headers,
  });

  // Combine the client state and http end point to a single link
  const link = ApolloLink.from([errorLink, restLink]);

  // persistentDefaults are directly written to the client cache and overwritten then by anything
  // in the persistent cache below;
  cache.writeData({
    data: merge(
      persistentDefaults,
    ),
  });

  const cachePersistenceEnabled = publicRuntimeConfig.APOLLO_CACHE_PERSISTENCE_ENABLED === 'true';
  const cachePersistenceCompressionEnabled = publicRuntimeConfig.APOLLO_CACHE_PERSISTENCE_COMPRESSION_ENABLED === 'true';

  // If cache persistence is disabled, remove all stored caches - Prevents overflows in the event
  // we turn this off without a deployment and users retain their caches
  if (!cachePersistenceEnabled) {
    removeGenericStorageItem(SCHEMA_KEY, StorageType.LOCAL_STORAGE);
  }

  // If client side
  if (process.browser && cachePersistenceEnabled) {
    // Setup cache persistor
    const persistor = new CachePersistor({
      cache,
      storageKey: SCHEMA_KEY,
      store: window.localStorage,
      verbose: publicRuntimeConfig.ENVIRONMENT.VerboseFELogging,
      enableCompression: cachePersistenceCompressionEnabled,
      storageLimit: publicRuntimeConfig.APOLLO_CACHE_PERSIST_LIMIT_BYTES,
    });

    const cacheExpiry = await window.localStorage.getItem(CACHE_EXPIRY_KEY);
    const now = new Date();

    if (cacheBusterRefreshMinutes && (!cacheExpiry || isBefore(cacheExpiry, now))) {
      // Reset the cache expiry date
      await window.localStorage.setItem(
        CACHE_EXPIRY_KEY,
        addMinutes(new Date(), cacheBusterRefreshMinutes),
      );
      // Purge the cache
      persistor.purge();
    }

    // Read the current schema version & compression status from AsyncStorage.
    const previousVersion = getGenericStorageItem(SCHEMA_VERSION_KEY, StorageType.LOCAL_STORAGE);
    const previousCompressionState = getGenericStorageItem(
      SCHEMA_COMPRESSION_ENABLED,
      StorageType.LOCAL_STORAGE,
    );

    if (document) {
      // We only restore the cache from persisted storage if we're on the same code version
      // and the storage scheme is the same (i.e. compression was enabled before and after)
      const currentVersion = getReadableCookieValue(SCHEMA_VERSION_KEY);
      const isSameVersion = previousVersion === currentVersion;
      const isSameStorageScheme = previousCompressionState === cachePersistenceCompressionEnabled;
      const shouldRestoreCache = isSameStorageScheme && isSameVersion;
      if (shouldRestoreCache) {
        // If the current version matches the latest version,
        // we're good to go and can restore the cache.
        await persistor.restore();
      } else {
        // Otherwise, we'll want to purge the outdated persisted cache
        // and mark ourselves as having updated to the latest version.
        persistor.purge();
        // Update the keys in localStorage so we can compare on next load
        setGenericStorageItem(SCHEMA_VERSION_KEY, currentVersion, StorageType.LOCAL_STORAGE);
        setGenericStorageItem(
          SCHEMA_COMPRESSION_ENABLED,
          cachePersistenceCompressionEnabled,
          StorageType.LOCAL_STORAGE,
        );
      }
    }

    // This handles all persisting, triggers whenever the cache updates.
    // We also provide .persist() for custom triggering
    persistor.watchCache();
  }

  const client = new ApolloClient({
    addTypename: true,
    cache,
    // If not SSR then enable dev tools
    connectToDevTools: process.browser,
    // Disables forceFetch on the server (so queries are only run once)
    link,
    // State resolvers are added to the client
    resolvers:
      merge(
        allResolvers,
        ...Object.keys(resolvers).map(key => (
          resolvers[key].resolvers ? resolvers[key].resolvers : {}
        )),
      ),
    ssrMode: !process.browser,
    typeDefs,
  });

  // Defaults are directly written to the client cache, these overwrite anything that
  // persists in persistent cache (localStorage)
  cache.writeData({
    data: merge(
      allDefaults,
      ...Object.keys(resolvers).map(key => (
        resolvers[key].defaults ? resolvers[key].defaults : {}
      )),
    ),
  });

  return client;
};

// DEFAULT EXPORT FOR SSR AND CLIENT SIDE
// ------------------------------------------------------
export default (initialState) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  dataLayerManager.setApolloClient(apolloClient);

  // apolloClient.client.onResetStore(stateLink.writeDefaults);

  // return the built apollo client from the initial state and the local state
  return apolloClient;
};
